<template>
    <div class="component-modal-alert">

        <div :key="index" class="modal-alert" v-for="(item, index) in alerts">
            <div class="modal-alert-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal-title">{{item.titulo}}</div>
                        <button type="button" class="close"  @click="botaoFechar($event, item.idAlert)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-html="item.mensagem"></div>
                    </div>
                    <div class="modal-footer">
                        <button @click="botaoOk($event, item.idAlert)">OK</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'ComponentModalAlert',
        props: [],
        components: {},//components
        data() {
            return {
                alerts: this.$store.getters['ModalAlert/alerts']
            }
        },//data
        computed: {},//computed
        methods: {

            fechar(e, idAlert) {
                this.$store.dispatch('ModalAlert/removerAlert', idAlert)
            },

            //Handlers
            botaoFechar(e, idAlert) {
                this.fechar(e, idAlert)
            },

            botaoOk(e, idAlert) {
                this.fechar(e, idAlert)
            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {}//updated
    }
</script>

<style lang="scss" scoped>
    h5
    {
        margin-bottom: 0;
    }

    // .component-modal-alert
    // {

    // }

    .modal-alert
    {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100000;
        .modal-alert-dialog{
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
            width:600px;
            max-width: 90%;
            .modal-content {
                position: relative;        
                display: flex;
                flex-direction: column;
                width: 100%;
                pointer-events: auto;
                background-color: #fff;
                border: 1px solid rgba(0,0,0,.2);
                border-radius: .3rem;
                outline: 0;
                .modal-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 1rem ;
                    border-bottom: 1px solid #e9ecef;
                    border-top-left-radius: .3rem;
                    border-top-right-radius: .3rem;
                    height: 50px;
                    .modal-title{
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 1.5em;
                    }
                    .close{
                        padding: 0px 5px;
                        font-size: 1.5em;
                        height: 1em;
                        line-height: 1em;
                        margin-right: -1rem 0 -1rem auto;
                        cursor: pointer
                    }                   
                }
                .modal-body {
                    position: relative;
                    flex: 1 1 auto;
                    padding: 1rem;
                }
                .modal-footer {
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding: 1rem;
                    border-top: 1px solid #e9ecef;
                }
            }
        }
    }


    // .modal-alert
    // {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     background-color: rgba(0, 0, 0, 0.5);
    //     height: 100%;
    //     left: 0;
    //     position: fixed;
    //     top: 0;
    //     width: 100%;
    //     z-index: 100000;
    //     .modal-alert-dialog{
    //         background-color: #fff;
    //         border-radius: 5px;
    //         box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
    //         width:600px;
    //         .modal-header{
    //             display: flex;
    //             align-items: center;
    //             justify-content: space-between;
    //             padding: 0 1rem ;
    //             border-bottom: 1px solid #e9ecef;
    //             border-top-left-radius: .3rem;
    //             border-top-right-radius: .3rem;
    //             .modal-title{
    //                 font-size: 18px;
    //                 font-weight: 500;
    //                 line-height: 0;
    //             }
    //             .close{
    //                 padding: 0px 5px;
    //                 font-size: 1.5em;
    //                 height: 1em;
    //                 line-height: 1em;
    //                 margin-right: -1rem 0 -1rem auto;
    //                 cursor: pointer
    //             }               
    //         }
    //         .modal-body {
    //             position: relative;
    //             flex: 1 1 auto;
    //             padding: 1rem;
    //         }
    //         .modal-footer {
    //             display: -ms-flexbox;
    //             display: flex;
    //             align-items: center;
    //             justify-content: flex-end;
    //             padding: 1rem;
    //             border-top: 1px solid #e9ecef;
    //         }
    //     }
    // }

    

    // .modal-alert-header
    // {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;

    //     margin-bottom: 1em;
    // }

    // .modal-alert-header button
    // {
    //     background-color: transparent;
    //     border: none;
    //     font-size: 1.5em;
    //     opacity: 0.5;
    //     outline: 0;
    //     padding: 0;
    // }

    // .modal-alert-body
    // {
    //     font-size: 0.9em;
    //     line-height: 130%;
    //     overflow-wrap: break-word;
    // }

    // .modal-alert-footer
    // {
    //     margin-top: 1em;
    //     text-align: center;
    // }

    // .modal-alert-footer button
    // {
    //     background-color: transparent;
    //     border: 1px solid #333;
    //     border-radius: 4px;
    //     color: #333;
    //     font-size: 1em;
    //     font-weight: 600;
    //     opacity: 0.35;
    //     outline: 0;
    //     padding: 0.18em 1em;
    // }
</style>
