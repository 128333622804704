<template>
	<div class="vw__v2">
		<HeaderVW
			@botaoLogout="botaoLogout"
			@botaoRedirecionarLogin="botaoRedirecionarLogin"
			@botaoRedirecionarMinhaConta="botaoRedirecionarMinhaConta"
			@botaoDesselecionarCidadeClick="botaoDesselecionarCidadeClick"
			@botaoMinhaLocalizacaoClick="botaoMinhaLocalizacaoClick"
			:autenticado="autenticado"
			:selecionouCidade="selecionouCidade"
			:cidadeSelecionada="cidadeSelecionada"
			@newSearch="newSearch"
			:submenuConfig="submenuConfig"
			:ALGOLIA_INDEX_PRODUCTS="ALGOLIA_INDEX_PRODUCTS"
		></HeaderVW>
		<div class="blue-friday-layout">
			<nuxt />

			<client-only>
				<component-minha-localizacao class="component-minha-localizacao" @confirmou-nova-cidade="componentMinhaLocalizacaoConfirmouNovaCidade" :config-minha-localizacao="configMinhaLocalizacao" v-if="$epecaHubB2c.featuresJson.GEOLOCATION_SELECT" />
			</client-only>
		</div>
	</div>
</template>

<script>
let API_B2C_BACKEND = process.env.API_B2C_BACKEND
let ALGOLIA_INDEX_PRODUCTS = process.env.ALGOLIA_INDEX_PRODUCTS
let TOKEN_EPA = process.env.TOKEN_EPA

import HeaderVW from "@/components/Common/HeaderVW"
export default {
	components: {
		HeaderVW,
		"component-minha-localizacao": () => {
			if (process.client) {
				return import("../components/ComponentMinhaLocalizacao.vue")
			}
		},
	},
	data() {
		return {
			ALGOLIA_INDEX_PRODUCTS: ALGOLIA_INDEX_PRODUCTS,
			termoBusca: "",
			configMinhaLocalizacao: {
				callbackAposSelecionarCidade: null,
				exibir: false,
				endpoint: {
					getTodasCidades: `${API_B2C_BACKEND}/geolocalizacao/cidades`,
					postLocalidadeViaCEP: `${API_B2C_BACKEND}/geolocalizacao?token=${TOKEN_EPA}`,
					postLocalidadePorLatLong: `${API_B2C_BACKEND}/geolocalizacao?token=${TOKEN_EPA}`,
				},
			},
		}
	},
	computed: {
		autenticado() {
			return this.$store.getters["autenticado"]
		},
		cidadeSelecionada() {
			return this.$store.getters["cidadeSelecionada"]
		},
		selecionouCidade() {
			return this.$store.getters["selecionouCidade"]
		},
		submenuConfig() {
			const configJson = this.$epecaHubB2c.configJson
			const submenu = configJson.SUBMENU || null

			return submenu
		},
	},
	created() {
		this.$eventbus.$on("ComponentMinhaLocalizacao/abrir", (obj) => {
			this.configMinhaLocalizacao.exibir = true

			if (obj) {
				if (obj.callback) {
					this.configMinhaLocalizacao.callback = obj.callback
				}
			}
		})

		this.$eventbus.$on("ComponentMinhaLocalizacao/fechar", () => {
			this.configMinhaLocalizacao.exibir = false
		})

		this.$eventbus.$on("ModalConfirmCepBloqueado/abrir", (obj) => {
			this.modalConfirmCepBloqueado.exibir = true
		})

		this.$eventbus.$on("ModalConfirmCepBloqueado/fechar", () => {
			this.modalConfirmCepBloqueado.exibir = false
		})

		if (process.client) {
			window.addEventListener(
				"scroll",
				(e) => {
					if (this.$mq.match(/md|lg/)) {
						this.headerFloat = true
					}
				},
				false
			)
		}
	},
	methods: {
		botaoDesselecionarCidadeClick() {
			this.$store.dispatch("setStoreIds", [])
			this.$store.dispatch("definirCep", false)
			this.$store.dispatch("definirCidadeSelecionada", false)
			this.$store.dispatch("definirConcessionariaSelecionada", false)
			this.$storage.removeUniversal("geolocation")
		},
		botaoRedirecionarMinhaConta(e) {
			this.$nuxt.$router.push({
				path: `/minha-conta/meus-dados`,
			})
		},
		botaoRedirecionarLogin() {
			this.$nuxt.$router.push({
				path: `/usuario/login`,
			})
		},
		botaoMinhaLocalizacaoClick() {
			this.configMinhaLocalizacao.exibir = true
		},
		botaoLogout() {
			this.$store.dispatch("logout")
			this.$nuxt.$router.push({
				path: `/usuario/login`,
			})
		},
		newSearch(text) {
			this.termoBusca = text
			this.buscar()
		},
		buscar() {
			this.$store.dispatch("setLastSearch", this.termoBusca)
			this.$router.push({ path: "/lista/", query: { query: this.termoBusca } })
		},
		async componentMinhaLocalizacaoConfirmouNovaCidade(obj) {
			await this.$store.dispatch("definirCep", obj.cep)
			await this.$store.dispatch("definirCidadeSelecionada", obj.cidade)

			if (this.configMinhaLocalizacao.callback) {
				this.configMinhaLocalizacao.callback()
				this.configMinhaLocalizacao.callback = null
			}
		},
	},
}
</script>

<style lang="scss">
.blue-friday-layout {
	min-height: calc(100vh - 110px);
	background: linear-gradient(180deg, #001e50 0%, #000 100%);
	@media (min-width: 768px) {
		min-height: calc(100vh - 105px);
	}
}
</style>
