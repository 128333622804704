export function setAvailableStores(defaultStoreId) {
	if (!defaultStoreId || defaultStoreId.length === 0) {
		return []
	}

	if (defaultStoreId.length === 1) {
		let availArray = []
		availArray.push(`availability.${defaultStoreId}.tag_estoque:FEW_ITEMS`)
		availArray.push(`availability.${defaultStoreId}.tag_estoque:IN_STOCK`)
		return availArray
	}

	if (defaultStoreId.length > 1) {
		let availArray = []

		for (const [index, iterator] of defaultStoreId.entries()) {
			if (index === 0) {
				availArray.push(`availability.${iterator}.tag_estoque:FEW_ITEMS`)
				availArray.push(`availability.${iterator}.tag_estoque:IN_STOCK`)
			} else {
				let temporaryArray = []
				temporaryArray.push(`availability.${iterator}.tag_estoque:FEW_ITEMS`)
				temporaryArray.push(`availability.${iterator}.tag_estoque:IN_STOCK`)
				availArray.push(temporaryArray)
			}
		}
		return availArray
	}
}

export function checkProductAvailabilityKits(produto, lojasPrioritarias) {
	if (!lojasPrioritarias || lojasPrioritarias.length === 0) {
		return []
	}

	// Verificar o array dos kits
	if (produto.kit_lojas.length > 0) {
		let foundAvailability = []
		for (const storeId of lojasPrioritarias) {
			for (const kitLoja of produto.kit_lojas) {
				if (kitLoja == storeId) {
					foundAvailability.push(kitLoja)
				}
			}
			// console.log(storeId)
		}
		return foundAvailability[0]
	} else {
		return []
	}
}

export function checkProductAvailability(product, defaultStoreId) {
	if (!defaultStoreId || defaultStoreId.length === 0) {
		return []
	}

	if (Object.keys(product.availability).length !== 0) {
		let foundAvailability = []

		for (const storeId of defaultStoreId) {
			for (const [key, value] of Object.entries(product.availability)) {
				if (key == storeId) {
					if (value.tag_estoque !== "UNAVAILABLE") {
						foundAvailability.push(value)
						break
					}
				}
			}
		}
		if (foundAvailability.length > 0 && defaultStoreId.length > 0) {
			let findAvailability = foundAvailability.find((s) => s.id_loja == defaultStoreId[0])
			if (findAvailability) {
				return foundAvailability.find((s) => s.id_loja == defaultStoreId[0])
			} else {
				return foundAvailability[0]
			}
		} else {
			return {}
		}
	} else {
		return {}
	}
}
