<template>
    <div class="component-modal-icone-texto" v-show="show">
        <div class="container-dialog" @click.stop="">
            <div class="container-body">
                <div class="col-icone">
                    <img :src="icone.url" alt="" v-if="icone.url">
                </div>
                <div class="col-texto" v-html="texto"></div>
            </div>
            <div class="container-footer" v-if="mostrarBotaoOk">
                <button type="button" class="btn btn-primary" @click="botaoOkClick">OK</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ComponentModalIconeTexto',
        props: [],
        components: {},//components
        data() {
            return {
                icone: {
                    url: null
                },
                mostrarBotaoOk: null,
                texto: [],
                show: false
            }
        },//data
        computed: {},//computed
        filters: {},//filters
        methods: {

            //Events
            botaoFecharClick(e) {
                this.show = false
            },

            botaoOkClick(e) {
                this.show = false
            },

        },//methods
        watch: {},//watch
        created() {

            const vm = this

            this.$eventbus.$on(
                'ComponentModalIconeTexto/abrir',
                (obj) => {

                    this.icone.url = obj.icone.url
                    this.texto = obj.texto

                    if(obj.mostrarBotaoOk === false) {
                        vm.mostrarBotaoOk = false
                    } else {
                        vm.mostrarBotaoOk = true
                    }

                    this.show = true

                }
            )

            this.$eventbus.$on(
                'ComponentModalIconeTexto/fechar',
                () => {
                    this.show = false
                }
            )

        },//created
        mounted() {}//mounted
    }
</script>

<style scoped>
    .component-modal-icone-texto
    {
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10000;
    }

    .container-dialog
    {
        background-color: #fff;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        position: relative;
        z-index: 10001;
    }

    .container-header
    {
        display: flex;
        align-items: center;

        border-bottom: none;
        box-sizing: border-box;
        margin: 0;
        padding: 1em;
    }

    .container-body
    {
        display: flex;
        align-items: center;

        line-height: 140%;
        padding: 2em;
    }

    .col-icone
    {
        border-right: 1px solid #ddd;
        padding: 2em 3em;
    }

    .col-icone img
    {
        height: 160px;
        width: 160px;
    }

    .col-texto
    {
        color: #444;
        font-size: 1.3em;
        padding: 2em 3em;
    }

    .col-texto >>> div
    {
        padding: 0.5em 0;
    }

    .col-texto >>> a
    {
        font-weight: bold;
    }

    .container-footer
    {
        border-top: 1px solid #ddd;
        box-sizing: border-box;
        padding: 1em;
        text-align: right;
    }

    .container-footer button
    {
        background-color: transparent;
        border: 1px solid #444;
        color: #444;
        cursor: pointer;
        display: inline-block;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        margin-left: .75em;
        padding: .375rem .75rem;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {

    }
    @media (max-width: 767px) {

        .container-dialog
        {
            margin: 0 auto;
            max-width: 90%;
        }

    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {

    }
    @media (max-width: 991px) {


        .modal-body
        {
            display: block;
            line-height: 130%;
            padding: 0 1.5em;
        }

        .col-icone
        {
            border-bottom: 1px solid #ddd;
            border-right: none;
            padding: 0 1em 1.5em;
            text-align: center;
        }

        .col-icone img
        {
            max-height: 100px;
            max-width: 100px;
        }


        .col-texto
        {
            font-size: 1.1em;
            padding: 1em;
            text-align: center;
        }

        .col-texto > div
        {
            padding: 0.35em 0;
        }

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }
    @media (max-width: 1199px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
