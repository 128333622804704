export default function ({ app, route, redirect, store }) {

    const featuresJson = app.$epecaHubB2c.featuresJson;
    const rotasExclusivasSeller = [
        'checkout-'
    ];

    store.dispatch('Carrinho/carregarCarrinhoStorage');

    if(!featuresJson.SELLER) {

        if(rotasExclusivasSeller.some(element => route.name.indexOf(element) >= 0)) {

            if(process.client) {
                console.error(`[E-PEÇA HUB B2C] Acesso à rota (${route.name}) não autorizado`);
            }

            return redirect('/');

        }

    }

}
