import Vue from 'vue'

//State
export const state = () => (
    {
        alerts: []
    }
) 

//Getters
export const getters = {
    alerts: (state) => {
        return state.alerts
    }
}

//Actions
export const actions = {

    adicionarAlert(context, obj) {
        context.commit('ADICIONAR_ALERT', obj)
    },

    removerAlert(context, idAlert) {
        context.commit('REMOVER_ALERT', idAlert)
    }

}

//Mutations
export const mutations = {

    ADICIONAR_ALERT(state, obj) {
        state.alerts.push(obj)
    },

    REMOVER_ALERT(state, idAlert) {

        state.alerts.forEach(
            (value, index, array) => {

                if(value.idAlert === idAlert)
                    state.alerts.splice(index, 1)

            }
        )

    }

}

