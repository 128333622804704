<template>
	<div class="container-error max-w-lg m-auto">
		<h1 class="title-error" v-if="error.statusCode === 404">Página não encontrada</h1>
		<h1 class="title-error" v-else>Ocorreu um erro.</h1>
		<button @click="voltarParaHome" class="btn__primary max-w-xs m-auto mt-4">Voltar para home</button>
	</div>
</template>

<script>
export default {
	props: ["error"],
	components: {},
	layout: "error",
	methods: {
		voltarParaHome() {
			this.$nuxt.$router.push({
				path: `/`,
			})
		},
	},
}
</script>
<style lang="scss" scoped>
h1 {
	color: #6a767d;
	text-align: center;
	font-size: 24px;
	line-height: 29px;
	@media (min-width: 768px) {
		font-size: 48px;
		line-height: 59px;
	}
}
</style>
