import Vue from "vue";

const currency = require("currency.js");

Vue.filter("formatarCep", (value) => {
  let ret = "";

  try {
    if (value.match(/[0-9]{8}/)) {
      ret = value.split("");

      ret.splice(5, 0, "-");

      ret = ret.join("");
    }
  } catch (err) {}

  return ret;
});

Vue.filter("formatarMoeda", (value) => {
  let ret = "";

  if (value !== "") {
    // ret = accounting.formatMoney(value, "R$ ", 2, ".", ",");
    ret = currency(value, {
      precision: 2,
      symbol: "R$ ",
      separator: ".",
      decimal: ",",
    }).format();
  }

  return ret;
});
