import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _516c45d4 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _9c802310 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _f165cf10 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _026136ac = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _6f1eb616 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _10810e8e = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _9c758e0a = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _c9665c1c = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _2014443e = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _8a7b03d8 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _b58523b2 = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _3f5d51ed = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _04aa01ab = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _36ccdd31 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _6d3bf747 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _5c26038b = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _77715730 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _35bc48cd = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _7c244e62 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _243e0a36 = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _77cfbe4e = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _516c45d4,
    name: "kits"
  }, {
    path: "/lojas",
    component: _9c802310,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _f165cf10,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _026136ac,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _6f1eb616,
    name: "motos"
  }, {
    path: "/reset",
    component: _10810e8e,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _9c758e0a,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _c9665c1c,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _2014443e,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _8a7b03d8,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _b58523b2,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _3f5d51ed,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _04aa01ab,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _36ccdd31,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _6d3bf747,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _5c26038b,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _77715730,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _35bc48cd,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _7c244e62,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _243e0a36,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _77cfbe4e,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
