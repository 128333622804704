import axios from "axios"

export default async ({ app, store }, inject) => {
	let configJson = null
	let featuresJson = null
	let localesJson = null
	let bannerJson = null
	let marketingJson = null

	let epecaHubB2c = null

	await axios.get(`${process.env.baseUrl}/custom/loja/marketingInfo.json`).then((response) => {
		marketingJson = response.data

		store.dispatch("definirMarketingJson", marketingJson)
	})

	await axios.get(`${process.env.baseUrl}/custom/loja/banner.json`).then((response) => {
		bannerJson = response.data

		store.dispatch("definirBannerJson", bannerJson)
	})

	await axios.get(`${process.env.baseUrl}/custom/loja/config.json`).then((response) => {
		configJson = response.data

		store.dispatch("definirConfigJson", configJson)
	})

	await axios.get(`${process.env.baseUrl}/custom/loja/features.json`).then((response) => {
		featuresJson = response.data

		store.dispatch("definirFeaturesJson", featuresJson)
	})

	await axios.get(`${process.env.baseUrl}/custom/loja/locales.json`).then((response) => {
		localesJson = response.data

		store.dispatch("definirLocalesJson", localesJson)
	})

	epecaHubB2c = {
		configJson,
		featuresJson,
		localesJson,
	}

	inject("epecaHubB2c", epecaHubB2c)
}
