<template>
	<div class="locationpopup" v-if="isVisible" @mouseover="resetTimeout" @mouseleave="startTimeout">
		<div class="locationpopup__empty">
			<div class="locationpopup__infotext font-16 head-regular">Para ver as melhores ofertas e prazos para sua região informe sua localização.</div>

			<Loader v-if="loadingGeolocation" />
			<button class="btn__primary" v-else @click.stop.prevent="getLocationBrowser">
				<svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M6.5 8.5C5.675 8.5 5 7.78 5 6.9C5 6.02 5.675 5.3 6.5 5.3C7.325 5.3 8 6.02 8 6.9C8 7.78 7.325 8.5 6.5 8.5ZM11 7.06C11 4.156 9.0125 2.1 6.5 2.1C3.9875 2.1 2 4.156 2 7.06C2 8.932 3.4625 11.412 6.5 14.372C9.5375 11.412 11 8.932 11 7.06ZM6.5 0.5C9.65 0.5 12.5 3.076 12.5 7.06C12.5 9.716 10.4975 12.86 6.5 16.5C2.5025 12.86 0.5 9.716 0.5 7.06C0.5 3.076 3.35 0.5 6.5 0.5Z"
						fill="#FAFAFA"
					/></svg
				>Minha localização
			</button>
			<div class="locationpopup__infotext font-16 head-regular">ou informe o CEP do local de entrega.</div>
			<input class="locationpopup__inputtext" type="tel" placeholder="Digite o CEP" v-model="cep" v-mask="['########']" />
			<span v-if="errorGeolocation !== ''">{{ errorGeolocation }}</span>
		</div>
	</div>
</template>

<script>
import DefaultButton from "@/components/Common/DefaultButton"
import Loader from "@/components/Common/Loader"
import { mask } from "vue-the-mask"
import axios from "axios"

const API_B2C_BACKEND = process.env.API_B2C_BACKEND
const TOKEN_EPA = process.env.TOKEN_EPA

export default {
	components: {
		DefaultButton,
		Loader,
	},
	directives: { mask },
	data() {
		return {
			loadingGeolocation: false,
			errorGeolocation: "",
			cep: "",
			isVisible: true,
			modalTimeout: "",
		}
	},
	props: {
		timeout: {
			type: Number,
			default: 7000,
		},
	},
	computed: {
		selecionouCidade() {
			return this.$store.getters["selecionouCidade"]
		},
	},
	methods: {
		async confirmLocation(locationData) {
			this.$storage.setUniversal("geolocation", locationData)
			if (locationData.lojas_prioritarias.length > 0) {
				this.$store.dispatch("setStoreIds", locationData.lojas_prioritarias)
			}
			this.$store.dispatch("definirCep", locationData.cep)
			this.$store.dispatch("definirCidadeSelecionada", locationData.cidade)
		},
		async getLocationByPosition(lat, lon) {
			this.errorGeolocation = ""
			this.loadingGeolocation = !this.loadingGeolocation
			const positions = { lat: lat, long: lon }
			try {
				const res = await axios.post(API_B2C_BACKEND + `/geolocalizacao?token=${TOKEN_EPA}`, positions)
				const locationData = res.data.data
				this.confirmLocation(locationData)

				delete locationData.lojas_prioritarias

				if (process.env.TOKEN_EPA) {
					let $epa = new window._epa({
						cKey: process.env.TOKEN_EPA,
					})

					$epa.send("popup-geolocalizacao-browser", { data: locationData })
				}
			} catch (error) {
				this.errorGeolocation = error.response.data.message
			}
			this.loadingGeolocation = !this.loadingGeolocation
		},
		async getLocationByCep(cep) {
			this.errorGeolocation = ""
			this.loadingGeolocation = !this.loadingGeolocation
			try {
				const res = await axios.post(API_B2C_BACKEND + `/geolocalizacao?token=${TOKEN_EPA}`, {
					cep: cep,
				})
				const locationData = res.data.data

				this.confirmLocation(locationData)

				delete locationData.lojas_prioritarias

				if (process.env.TOKEN_EPA) {
					let $epa = new window._epa({
						cKey: process.env.TOKEN_EPA,
					})

					$epa.send("popup-geolocalizacao-cep", { data: locationData })
				}
			} catch (error) {
				this.errorGeolocation = error.response.data.message
			}
			this.loadingGeolocation = !this.loadingGeolocation
		},
		getLocationBrowser() {
			navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoError)
		},
		geoSuccess(position) {
			this.getLocationByPosition(position.coords.latitude, position.coords.longitude)
		},
		geoError(err) {
			switch (err.code) {
				case 1:
					console.log("geolocalização: solicitação não aceita pelo usuário")
					break

				case 2:
					console.log("geolocalização: não foi possível alcançar os satélites GPS")
					break

				case 3:
					console.log("geolocalização: a requisição demorou demais para retornar")
					break

				case 0:
					console.log("geolocalização: ocorreu um erro desconhecido...")
					break
			}
		},
		encontrarLocalizacaoBrowser() {
			navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoError)
		},
		resetTimeout() {
			clearTimeout(this.modalTimeout)
		},
		startTimeout() {
			this.modalTimeout = setTimeout(() => {
				this.isVisible = !this.isVisible
			}, this.timeout)
		},
	},
	mounted() {
		this.startTimeout()
	},
	watch: {
		cep(newValue) {
			if (newValue.length === 8 || newValue === 9) {
				this.getLocationByCep(newValue)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.locationpopup {
	position: absolute;
	width: 270px;
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	padding: 24px;
	top: 28px;
	left: inherit;
	display: flex;
	flex-direction: column;
	gap: 16px;
	&__empty,
	&__filled {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	&__inputtext {
		border: 1px solid #96a3a8;
		height: 40px;
		padding: 8px;
		color: #565656;
	}
	&__infotext {
		text-align: center;
		color: #6f6f6f;
	}
}

@media (min-width: 768px) {
	.locationpopup {
		left: 28px;
	}
}
.location-popup-default {
	top: 24px;
	right: initial;
	left: -24px;
	@media (min-width: 768px) {
		top: 24px;
		right: 0;
		left: initial;
	}
}
</style>
