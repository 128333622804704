export default ({ $axios, app }, inject) => {
  let API_B2C_BACKEND = process.env.API_B2C_BACKEND;

  inject("getProducts", async (url, page, compatibilidade) => {
    url = url.replace(/^\//, "");

    const { data } = await $axios.post(API_B2C_BACKEND + "/produtos", {
      cep: "",
      compatibilidade,
      paginacao: page,
      url: url,
    });

    return data;
  }),
    inject("getProduct", async (id) => {
      const { data } = await $axios.post(API_B2C_BACKEND + "/produtos", {
        cep: "",
        compatibilidade: false,
        url: `produto/${id}`,
      });

      return data;
    });
};
