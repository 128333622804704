const authMixin = {
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$nuxt.$router.push({
        path: `/usuario/login`,
      });
    },
    clearCart() {
      this.$store.dispatch("Carrinho/esvaziarCarrinho");
    },
    clearLocation() {
      this.$store.dispatch("setStoreIds", []);
      this.$store.dispatch("definirCep", false);
      this.$store.dispatch("definirCidadeSelecionada", false);
      this.$store.dispatch("definirConcessionariaSelecionada", false);
      this.$storage.removeUniversal("geolocation");
    },
  },
};

export default authMixin;
