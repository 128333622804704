<template>
	<div class="vw__v2">
		<HeaderVW
			@botaoLogout="botaoLogout"
			@botaoRedirecionarLogin="botaoRedirecionarLogin"
			@botaoRedirecionarMinhaConta="botaoRedirecionarMinhaConta"
			@botaoDesselecionarCidadeClick="botaoDesselecionarCidadeClick"
			@botaoMinhaLocalizacaoClick="botaoMinhaLocalizacaoClick"
			:autenticado="autenticado"
			:selecionouCidade="selecionouCidade"
			:cidadeSelecionada="cidadeSelecionada"
			@newSearch="newSearch"
			:searchClient="searchClient"
			:submenuConfig="submenuConfig"
			:ALGOLIA_INDEX_PRODUCTS="ALGOLIA_INDEX_PRODUCTS"
		></HeaderVW>

		<section id="layout-default">
			<main class="main__v3 header-float">
				<div class="minha-conta-header">
					<h1>Minha conta</h1>
					<button class="btn__primary" style="max-width: 154px" @click="logout()">Sair</button>
				</div>
				<div class="minha-conta">
					<div class="minha-conta-nav">
						<a class="minha-conta-link" :class="{ active: $route.name === 'minha-conta-meus-dados' }" href.prevent="#" @click="$router.push({ name: 'minha-conta-meus-dados' })"> <span class="icon icon-meus-dados mr-2"></span><span class="block">Meus dados</span></a>

						<a class="minha-conta-link" :class="{ active: $route.name === 'minha-conta-enderecos' }" href.prevent="#" @click="$router.push({ name: 'minha-conta-enderecos' })"><span class="icon icon-meus-enderecos mr-2"></span><span class="block">Meus endereços</span></a>
						<a class="minha-conta-link" :class="{ active: $route.name === 'minha-conta-pedidos' }" href.prevent="#" @click="$router.push({ name: 'minha-conta-pedidos' })">
							<span class="icon icon-meus-pedidos mr-2"></span><span class="block">Meus pedidos</span>

							<!-- <img class="md:mr-2" src="/custom/loja/assets/media/icons/meus-pedidos.svg" /><span class="hidden md:block">Meus pedidos</span> -->
						</a>
					</div>
					<div class="minha-conta-content">
						<nuxt />
					</div>
				</div>
			</main>

			<client-only>
				<component-minha-localizacao class="component-minha-localizacao" @confirmou-nova-cidade="componentMinhaLocalizacaoConfirmouNovaCidade" :config-minha-localizacao="configMinhaLocalizacao" v-if="$epecaHubB2c.featuresJson.GEOLOCATION_SELECT" />
			</client-only>

			<ComponentLGPD class="component-lgpd" :config="componentLgpdConfig" />
			<modal-alert></modal-alert>

			<ComponentModalIconeTexto />
		</section>

		<div class="footer__wrapper">
			<div v-if="$route.name !== 'index'" class="container mx-auto" style="max-width: 1220px">
				<hr />
			</div>
			<div class="descricao__home py-8 px-4 md:px-0" v-else>
				<div class="container mx-auto" style="max-width: 1220px">
					<h6 class="mb-4">{{ footerValues.description.title }}</h6>
					<p>{{ footerValues.description.text }}</p>
				</div>
			</div>
			<Footer :values="footerValues" />
		</div>

		<Whatsapp :textoWhatsapp="`Olá, estou com dúvidas sobre um pedido. Poderia me ajudar?`"></Whatsapp>
	</div>
</template>

<script>
import Whatsapp from "@/components/atoms/Whatsapp"
import algoliasearch from "algoliasearch/lite"
import ComponentLGPD from "~/components/ComponentLGPD"
import ComponentModalIconeTexto from "~/components/ComponentModalIconeTexto.vue"
import ModalAlert from "~/components/ModalAlert.vue"
import HeaderVW from "@/components/Common/HeaderVW"
import authMixin from "@/mixins/auth"

let API_B2C_BACKEND = process.env.API_B2C_BACKEND
let ALGOLIA_INDEX_PRODUCTS = process.env.ALGOLIA_INDEX_PRODUCTS
let TOKEN_EPA = process.env.TOKEN_EPA

export default {
	components: {
		Whatsapp,
		HeaderVW,
		ComponentLGPD,
		"component-minha-localizacao": () => {
			if (process.client) {
				return import("../components/ComponentMinhaLocalizacao.vue")
			}
		},
		ModalAlert,
		ComponentModalIconeTexto,
	},
	mixins: [authMixin],
	data() {
		return {
			query: "",
			searchClient: null,
			componentLgpdConfig: {
				keyCookie: "epeca-hub-b2c/politicas-de-privacidade/aceitar-cookies",
				botaoConfirm: "Continuar",
				filesPrivacyPolicy: this.$epecaHubB2c.configJson.FILES_PRIVACY_POLICY,
				textCookie: this.$epecaHubB2c.configJson.LGPD_TEXT_COOKIE,
				linkPrivacyPolicy: this.$epecaHubB2c.configJson.LGPD_TEXT_COOKIE_LINK,
			},
			ALGOLIA_INDEX_PRODUCTS: ALGOLIA_INDEX_PRODUCTS,

			configMinhaLocalizacao: {
				callbackAposSelecionarCidade: null,
				exibir: false,
				endpoint: {
					getTodasCidades: `${API_B2C_BACKEND}/geolocalizacao/cidades`,
					postLocalidadeViaCEP: `${API_B2C_BACKEND}/geolocalizacao?token=${TOKEN_EPA}`,
					postLocalidadePorLatLong: `${API_B2C_BACKEND}/geolocalizacao?token=${TOKEN_EPA}`,
				},
			},

			headerFloat: false,
			modalConfirmCepBloqueado: {
				exibir: false,
			},

			termoBusca: "",
		}
	},
	computed: {
		cliente() {
			return process.env.envName
		},
		footerValues() {
			return this.$epecaHubB2c.configJson.FOOTER_VALUES
		},
		usuario() {
			let objUsuario = this.$store.getters["usuario"]
			let usuario = objUsuario.nome_razao.split(" ")[0]
			return usuario
		},

		autenticado() {
			return this.$store.getters["autenticado"]
		},
		cidadeSelecionada() {
			return this.$store.getters["cidadeSelecionada"]
		},

		concessionariaSelecionada() {
			return this.$store.getters["concessionariaSelecionada"]
		},
		selecionouCidade() {
			return this.$store.getters["selecionouCidade"]
		},

		submenuConfig() {
			const configJson = this.$epecaHubB2c.configJson
			const submenu = configJson.SUBMENU || null

			return submenu
		},

		veiculoSelecionado() {
			return this.$store.getters["veiculoSelecionado"]
		},

		veiculoSelecionadoNome() {
			return this.$store.getters["veiculoSelecionadoNome"]
		},
	},
	methods: {
		newSearch(text) {
			this.termoBusca = text
			this.buscar()
		},
		onSelect(selected) {
			if (selected) {
				this.termoBusca = selected.item.query
			}
		},
		indicesToSuggestions(indices) {
			return indices.map(({ hits }) => ({
				data: hits,
			}))
		},
		buscar() {
			const route = this.$nuxt.$route
			if (this.termoBusca !== "") {
				if (route.path === "/lista/") {
					this.$nuxt.$router.push({
						name: "lista-redirect",
						params: {
							path: route.path,
							query: route.query,
							termoBusca: this.termoBusca,
						},
					})
				} else {
					this.$nuxt.$router.replace({
						path: "/lista/",
						query: {
							query: this.termoBusca,
							origem: "busca",
						},
					})
				}
				this.$store.dispatch("setLastSearch", this.termoBusca)
			}
		},

		definirTermoBusca() {
			const routeQuery = this.$nuxt.$route.query

			if (routeQuery) {
				if (routeQuery.busca) {
					this.termoBusca = routeQuery.busca
				}
			}
		},

		//Events

		botaoBuscarClick(e) {
			this.buscar()
		},

		botaoDesselecionarConcessionariaClick(e) {
			this.$store.dispatch("definirConcessionariaSelecionada", false)
		},

		botaoDesselecionarVeiculoClick(e) {
			this.$store.dispatch("definirVeiculoSelecionado", false)
			this.$eventbus.$emit("meus-veiculos/nenhuma-das-opcoes", true)
		},

		botaoDesselecionarCidadeClick(e) {
			this.$store.dispatch("setStoreIds", [])
			this.$store.dispatch("definirCep", false)
			this.$store.dispatch("definirCidadeSelecionada", false)
			this.$store.dispatch("definirConcessionariaSelecionada", false)
		},

		botaoMinhaLocalizacaoClick(e) {
			this.configMinhaLocalizacao.exibir = true
		},
		botaoRedirecionarLogin(e) {
			this.$nuxt.$router.push({
				path: `/usuario/login`,
			})
		},
		botaoRedirecionarMinhaConta(e) {
			this.$nuxt.$router.push({
				path: `/minha-conta/meus-dados`,
			})
		},
		botaoLogout(e) {
			this.$store.dispatch("logout")
			this.$nuxt.$router.push({
				path: `/usuario/login`,
			})
		},
		async componentMinhaLocalizacaoConfirmouNovaCidade(obj) {
			await this.$store.dispatch("definirCep", obj.cep)
			await this.$store.dispatch("definirCidadeSelecionada", obj.cidade)

			if (this.configMinhaLocalizacao.callback) {
				this.configMinhaLocalizacao.callback()
				this.configMinhaLocalizacao.callback = null
			}
		},

		verificarAutenticado() {
			const lsToken = localStorage.getItem("epeca-hub-b2c/token")
			const cookiesToken = this.$cookies.get("epeca-hub-b2c/token")
			if (lsToken && cookiesToken && lsToken === cookiesToken) {
				this.$store.dispatch("carregarUsuario").then(() => {
					this.$store.dispatch("definirAutenticado", true)
				})
			}
		},
	},
	watch: {
		$route: {
			handler: function (value, oldValue) {
				if (process.client) {
					if (process.env.TOKEN_EPA) {
						let $epa = new window._epa({
							cKey: process.env.TOKEN_EPA,
						})
						$epa.send("pageview")
					}
				}

				if (this.$gtmHelpers) {
					this.$gtmHelpers.sendEvent.basic({
						event: "pageview",
						pagePath: value.fullPath,
						pageType: value.name === "index" ? "home" : value.name,
					})
				}

				if (this.$helpers) {
					this.$helpers.verificarAutenticado({ app: this, routeTo: value })
				}

				if (value.name !== "lista") {
					this.termoBusca = ""
				}
			},
			immediate: true,
		},
	},
	created() {
		this.$eventbus.$on("ComponentMinhaLocalizacao/abrir", (obj) => {
			this.configMinhaLocalizacao.exibir = true

			if (obj) {
				if (obj.callback) {
					this.configMinhaLocalizacao.callback = obj.callback
				}
			}
		})

		this.$eventbus.$on("ComponentMinhaLocalizacao/fechar", () => {
			this.configMinhaLocalizacao.exibir = false
		})

		this.$eventbus.$on("ModalConfirmCepBloqueado/abrir", (obj) => {
			this.modalConfirmCepBloqueado.exibir = true
		})

		this.$eventbus.$on("ModalConfirmCepBloqueado/fechar", () => {
			this.modalConfirmCepBloqueado.exibir = false
		})

		if (process.client) {
			window.addEventListener(
				"scroll",
				(e) => {
					if (this.$mq.match(/md|lg/)) {
						this.headerFloat = true
					}
				},
				false
			)
		}
	}, //created
	beforeMount() {
		if (process.client) {
			const lsCep = localStorage.getItem("epeca-hub-b2c/cep")
			const lsCidade = localStorage.getItem("epeca-hub-b2c/cidade")
			let geolocation = this.$cookies.get("geolocation")
			const availableStores = localStorage.getItem("epeca-hub-b2c/availableStores")
			const lsConcessionaria = localStorage.getItem("epeca-hub-b2c/concessionaria")
			const lsVeiculoSelecionado = sessionStorage.getItem("epeca-hub-b2c/veiculo-selecionado")

			if (availableStores) {
				let arrAvailableStores = availableStores.split(",")
				this.$store.dispatch("setStoreIds", arrAvailableStores)
			}

			const parseConcessionaria = lsConcessionaria ? JSON.parse(lsConcessionaria) : null
			const parseVeiculoSelecionado = lsVeiculoSelecionado ? JSON.parse(lsVeiculoSelecionado) : null

			this.$store.dispatch("definirCep", lsCep)
			this.$store.dispatch("definirCidadeSelecionada", geolocation ? geolocation.cidade : "")
			this.$store.dispatch("definirConcessionariaSelecionada", parseConcessionaria)
			this.$store.dispatch("definirVeiculoSelecionado", parseVeiculoSelecionado)
			this.$store.dispatch("Carrinho/carregarCarrinhoStorage")
		}

		this.definirTermoBusca()

		this.$helpers.verificarAutenticado({ app: this })
	}, //beforeMount,
	mounted() {
		this.searchClient = algoliasearch(this.$epecaHubB2c.configJson.ALGOLIA_APPLICATION_ID, this.$epecaHubB2c.configJson.ALGOLIA_API_KEY_READ_ONLY)

		this.verificarAutenticado()

		if (process.client) {
			window.dataLayer = window.dataLayer || []

			window.dataLayer.push({
				originalLocation: document.location.protocol + "//" + document.location.hostname + document.location.pathname + document.location.search,
			})
		}
	}, //mounted
	head() {
		const metaTags = this.$epecaHubB2c.configJson.META_TAGS ? this.$epecaHubB2c.configJson.META_TAGS : []

		let script = []

		if (this.$epecaHubB2c.configJson.JIVO_ID) {
			script.push({
				async: true,
				src: `//code.jivosite.com/widget/${this.$epecaHubB2c.configJson.JIVO_ID}`,
			})
		}

		return {
			link: [
				// { rel: "stylesheet", href: this.$epecaHubB2c.configJson.FILES_CSS },
			],
			meta: [...metaTags],
			script,
			title: this.$epecaHubB2c.configJson.SEO_DEFAULT_TITLE,
		}
	},
}
</script>
<style lang="scss">
button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-family: inherit;
	font-size: 1em;
	outline: none;
	padding: 0;
}

input {
	font-family: inherit;
	font-size: 1em;
	outline: none;
}
.footer__wrapper {
	margin-top: 32px;
	.logo {
		img {
			height: 100px;
		}
	}
}
@media (min-width: 1220px) {
	.footer__wrapper {
		margin-top: 64px;
	}
}
</style>

<style lang="scss" scoped>
.header {
	align-items: center;
	display: flex;
	width: 100%;
	@media (max-width: 991px) {
		display: block;
	}
}

#layout-default {
	margin: 0 auto;
	position: relative;
}

header.header-float {
	background-color: #fff;
	box-sizing: border-box;
	position: fixed;
	top: 0;
	width: inherit;
	z-index: 1000;
}

header > div {
	display: flex;
	align-items: center;
	justify-content: space-between;

	box-sizing: border-box;
	margin: 0 auto;
	width: 100%;
}

header > div:first-child {
	display: flex;
	align-items: center;

	padding: 1.2rem 0;
}

main {
	margin-top: 24px;
}

.component-minha-localizacao#component-minha-localizacao ::v-deep .container-modal .container-conteudo {
	border-radius: 0;
}

.component-minha-localizacao#component-minha-localizacao ::v-deep .container-modal .container-conteudo .row-header {
	border-bottom: none;
}

.component-minha-localizacao#component-minha-localizacao ::v-deep .container-modal .container-conteudo .row-header h5 {
	color: #555;
	font-size: 1.35rem;
	letter-spacing: -0.025em;
	margin: 0;
	padding: 0;
}

.component-minha-localizacao#component-minha-localizacao ::v-deep .container-modal .container-conteudo .row-header h5 b {
	font-weight: 400;
}

.component-minha-localizacao#component-minha-localizacao ::v-deep .container-modal .container-conteudo .row-content .btn-encontrar-localizacao {
	border-radius: 0;
	padding: 1.5em;
}

.component-minha-localizacao#component-minha-localizacao ::v-deep .dropdown .dropdown-input {
	box-sizing: border-box;
}

.component-minha-localizacao#component-minha-localizacao ::v-deep .container-modal .container-conteudo .row-footer .botao-secundario {
	border-radius: 0;
}

.component-minha-localizacao#component-minha-localizacao ::v-deep .container-modal .container-conteudo .row-content .etapa-de-confirmar .btn-confirmar {
	border-radius: 0;
}

.component-minha-localizacao#component-minha-localizacao ::v-deep .component-view-carregando {
	display: flex;
	align-items: center;
	justify-content: center;
}

.component-minha-localizacao#component-minha-localizacao ::v-deep .component-view-carregando span {
	font-size: 1.35rem;
	opacity: 1;
}

.component-lgpd#component-lgpd ::v-deep > div {
	background-color: #fff;
	color: #000;
	box-shadow: 0 0 25px 2px rgb(0 0 0 / 15%);
	border-radius: 0;
	box-sizing: border-box;
}

.component-lgpd#component-lgpd ::v-deep .box-flutuante .btn-continuar {
	background-color: #fff;
	border-radius: 0;
	color: #111;
	margin-left: 0.5em;
}

@media (max-width: 767px) {
	.component-lgpd#component-lgpd ::v-deep .box-flutuante .btn-continuar {
		width: 100%;
	}

	footer .container-row-copyright {
		display: block;
		padding: 1.25rem;
	}

	footer .container-row-copyright > span {
		display: block;
		margin-bottom: 1em;
	}
}

@media (max-width: 991px) {
	header > div:first-child {
		padding: 1.2rem;
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	#layout-default {
		width: 90vw;
	}
}

@media (min-width: 1200px) {
	#layout-default {
		width: 1220px;
	}
}
#autosuggest input {
	font: inherit;
}
h1 {
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #3c484d;
	@media (min-width: 768px) {
		font-weight: 700;
		font-size: 32px;
		line-height: 39px;
	}
}
.minha-conta-header {
	display: flex;
	justify-content: space-between;
	margin: 0 16px;
	margin-bottom: 24px;
	@media (min-width: 768px) {
		margin: 0;
		margin-bottom: 32px;
	}
}
.minha-conta {
	display: flex;
	flex-direction: column;
	gap: 24px;
	@media (min-width: 1024px) {
		display: grid;
		grid-template-columns: 300px 1fr;
		gap: 24px;
	}
}
.minha-conta-content {
	margin: 0 16px;
	@media (min-width: 1024px) {
		margin: 0;
	}
}
.minha-conta-nav {
	display: flex;
	gap: 8px;
	flex-direction: column;
	margin: 0 16px;
	@media (min-width: 768px) {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin: 0;
	}
}
.minha-conta-link {
	height: 60px;
	display: flex;
	align-items: center;
	border-bottom: 2px solid #96a3a8;
	color: #96a3a8;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	padding: 0 16px;
	font-family: var(--head-bold);
	font-weight: 700;
	cursor: pointer;
	box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
	@media (min-width: 768px) {
		height: 60px;
		display: flex;
		align-items: center;
		border-bottom: 2px solid #96a3a8;
		color: #96a3a8;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		padding: 0 16px;
		font-family: var(--head-bold);
		font-weight: 700;
		cursor: pointer;
		box-shadow: none;
	}
	&.active {
		font-weight: 700;
		color: #3c484d;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
		border-color: var(--primary_color);
		.icon-meus-dados {
			background-image: url(/custom/loja/assets/media/icons/meus-dados-active.svg);
		}
		.icon-meus-enderecos {
			background-image: url(/custom/loja/assets/media/icons/meus-enderecos-active.svg);
		}
		.icon-meus-pedidos {
			background-image: url(/custom/loja/assets/media/icons/meus-pedidos-active.svg);
		}
	}
	&:hover {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
	}
}
.icon {
	display: inline-block;
	width: 24px;
	height: 24px;
	background-size: cover;
}
.icon-meus-dados {
	background-image: url(/custom/loja/assets/media/icons/meus-dados-inactive.svg);
	&:hover {
		background-image: url(/custom/loja/assets/media/icons/meus-dados-active.svg);
	}
}
.icon-meus-enderecos {
	background-image: url(/custom/loja/assets/media/icons/meus-enderecos-inactive.svg);
	&:hover {
		background-image: url(/custom/loja/assets/media/icons/meus-enderecos-active.svg);
	}
}
.icon-meus-pedidos {
	background-image: url(/custom/loja/assets/media/icons/meus-pedidos-inactive.svg);
	&:hover {
		background-image: url(/custom/loja/assets/media/icons/meus-pedidos-active.svg);
	}
}
.descricao__home {
	background: #f1f1f1;
	color: #3c484d;
	h6 {
		font-size: 16px;
		line-height: 19px;
		@media (min-width: 768px) {
			font-size: 24px;
			line-height: 29px;
		}
	}
	p {
		font-size: 12px;
		line-height: 15px;
		@media (min-width: 768px) {
			font-size: 16px;
			line-height: 19px;
		}
	}
}
</style>
