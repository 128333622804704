export default async ({ app }, inject) => {
	const { head } = app

	head.script.push({
		hid: "epa",
		src: "https://analytics.e-peca.com.br/api/epa.js",
	})

	function getGaItemsFromEpecaProducts(options) {
		const list = options.items

		let result = []

		list.forEach((product) => {
			let itemCategory = ""

			if (product.categoria) {
				if (product.categoria[0]) {
					if (product.categoria[0].departamento) {
						itemCategory += product.categoria[0].departamento + "/"
					}
					if (product.categoria[0].secao) {
						itemCategory += product.categoria[0].secao + "/"
					}
					if (product.categoria[0].grupo) {
						itemCategory += product.categoria[0].grupo + "/"
					}
					if (product.categoria[0].subgrupo) {
						itemCategory += product.categoria[0].subgrupo
					}

					itemCategory = itemCategory.replace(/\/$/, "")
				}
			}

			result.push(product)
		})

		return result
	}

	if (process.client) {
		let epaInstance = null

		const TOKEN_EPA = process.env.TOKEN_EPA
		const epaAnalyticsKey = TOKEN_EPA

		const createInstance = () => {
			epaInstance = new window._epa({ cKey: epaAnalyticsKey })
		}

		const getInstance = () => {
			return epaInstance
		}

		const send = (eventIdentifier, optionsRaw, misc, trackerName) => {
			if (epaInstance) {
				if (trackerName) {
					epaInstance.set("tracker", trackerName)
				}

				if (optionsRaw) {
					let contextIdentifier = ""

					let options = { ...optionsRaw }

					if (options.items) {
						options.items = getGaItemsFromEpecaProducts(options)
					}

					if (misc.route) {
						contextIdentifier = misc.route.name
					}

					epaInstance.send(contextIdentifier, {
						event: eventIdentifier,
						...options,
					})
				} else {
					epaInstance.send(eventIdentifier)
				}
			}
		}

		const epaHelpers = {
			createInstance,
			getInstance,
			send,
		}

		if (epaAnalyticsKey) {
			createInstance()
		}

		inject("epaHelpers", epaHelpers)
	}
}
