<template>
	<div id="header" class="header__wrapper header__sticky">
		<div class="header__vw">
			<div class="flex w-100 wrapperz pt-4 flex-col">
				<div class="moving__frame__top">
					<div class="wrapper-submenu flex lg1220:hidden" style="justify-content: flex-start; align-items: center">
						<div class="container-submenu lg1220:hidden">
							<client-only>
								<SubmenuVW :config="{ submenuConfig }" />
							</client-only>
						</div>
					</div>
				</div>

				<div class="moving__frame__wrapper">
					<img @click="$router.push({ path: '/' })" class="moving__frame__logo cursor-pointer" src="/custom/loja/assets/media/icons/logo-header.svg" alt="Logotipo" />
				</div>

				<div class="moving__frame__bottom">
					<!-- Submenu -->
					<div class="wrapper-submenu hidden lg1220:flex" style="justify-content: flex-start; align-items: center">
						<div class="container-submenu">
							<client-only>
								<SubmenuVW :config="{ submenuConfig }" />
							</client-only>
						</div>
					</div>

					<Localizacao class="lg1220:hidden" @botaoMinhaLocalizacaoClick="botaoMinhaLocalizacaoClick" :selecionouCidade="selecionouCidade" :cidadeSelecionada="cidadeSelecionada" />

					<!-- Actions -->
					<div class="actions flex items-center" style="display: flex; justify-content: flex-end; position: relative">
						<Localizacao class="hidden lg1220:block" @botaoMinhaLocalizacaoClick="botaoMinhaLocalizacaoClick" :selecionouCidade="selecionouCidade" :cidadeSelecionada="cidadeSelecionada" />

						<button class="ml-3" v-if="$epecaHubB2c.featuresJson.SELLER" @click="triggerProfile()">
							<svg v-if="!autenticado" id="_24px_profile_" data-name="24px/profile " xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
								<path
									id="profile"
									d="M10.477,20.954c-2.9-.016-7.52-.18-9.326-.328a1.248,1.248,0,0,1-.886-.477,1.267,1.267,0,0,1-.247-1l.817-5.009a3.1,3.1,0,0,1,1.582-2.382l3.328-1.5.545,1.213L3.02,12.941a1.77,1.77,0,0,0-.871,1.417l-.816,5.007c.49.023,1.22.054,2.073.085l1.341.047c1.9.062,4.093.119,5.735.128h.868c1.64-.009,3.833-.065,5.747-.139l1.34-.059c.826-.038,1.544-.079,2.056-.12l-.808-4.953a1.738,1.738,0,0,0-.893-1.423l-3.28-1.458.541-1.215,3.34,1.488a3.09,3.09,0,0,1,1.6,2.387l.817,5.017a1.271,1.271,0,0,1-.247,1,1.242,1.242,0,0,1-.883.474c-1.806.148-6.392.31-9.288.328h-.917ZM5.555,5.362A5.186,5.186,0,0,1,10.916,0a5.185,5.185,0,0,1,5.36,5.361,5.185,5.185,0,0,1-5.36,5.361A5.185,5.185,0,0,1,5.555,5.362Zm1.33,0a3.9,3.9,0,0,0,4.031,4.032,3.9,3.9,0,0,0,4.031-4.031A3.9,3.9,0,0,0,10.916,1.33,3.9,3.9,0,0,0,6.885,5.361Z"
									transform="translate(1.084 1.523)"
									fill="#001e50"
								/>
							</svg>
							<svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M6.09983 6.23488C6.10727 2.78546 8.75868 -0.00522068 12.0236 1.46611e-05C15.2884 0.00525 17.9318 2.80442 17.9294 6.25385C17.9269 9.70328 15.2795 12.4982 12.0146 12.4982C8.74578 12.4904 6.09982 9.68848 6.09983 6.23488ZM17.1779 13.1474C16.1083 14.7223 14.3521 14.6692 12.7759 14.6215C12.5118 14.6135 12.2529 14.6057 12.003 14.6057C11.7479 14.6052 11.4838 14.6129 11.2148 14.6207C9.71718 14.6642 8.06884 14.7121 6.99452 13.3735L6.329 11.6157L3.79602 12.6121C2.40761 13.171 1.38938 14.4458 1.10137 15.9858L0.0369772 21.452C-0.0701465 21.9985 0.0582108 22.567 0.387283 23.0036C0.711561 23.4476 1.20435 23.7183 1.73461 23.7438C5.15683 23.9146 8.58353 24 12.0147 24C15.4369 24 18.8726 23.9146 22.2679 23.7438C22.7963 23.7171 23.2875 23.4487 23.6135 23.0086C23.9395 22.5685 24.068 22.0003 23.9655 21.452L22.9416 15.9858C22.656 14.4446 21.6369 13.1687 20.2469 12.6121L17.7274 11.6157L17.1779 13.1474Z"
									fill="#001e50"
									id="profile-loggedin"
								/>
							</svg>
						</button>
						<button class="ml-3" v-if="$epecaHubB2c.featuresJson.SELLER" @click="$router.push({ path: '/checkout/carrinho' })" style="display: flex; align-items: center">
							<svg id="_24px_shopping_cart" data-name="24px/shopping cart" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
								<path
									id="shopping_cart"
									data-name="shopping cart"
									d="M12.686,19.535a.761.761,0,1,1,.761.76A.735.735,0,0,1,12.686,19.535Zm-9.13,0a.734.734,0,0,1,.761-.761.761.761,0,1,1,0,1.522A.734.734,0,0,1,3.557,19.535ZM2.8,17.765v-1.33H14.171l.846-3.54H2.49a.666.666,0,0,1-.65-.526L.014,3.849a.665.665,0,0,1,.651-.806H17.371l.6-2.532A.662.662,0,0,1,18.622,0h3.043V1.33H19.146L15.341,17.254a.662.662,0,0,1-.646.511Zm.233-6.2H15.335l1.717-7.191H1.488Zm8.995-1.617V5.991h1.329V9.948Zm-3.35,0V5.991H10V9.948Zm-3.348,0V5.991h1.33V9.948Z"
									transform="translate(1.366 2.704)"
									fill="#001e50"
								/>
							</svg>
							<span id="notification-header" class="ml-1 quantidade-produtos notification-bubble font-12" :class="{ expanded: quantidadeProdutosAdicionados > 9 }">{{ quantidadeProdutosAdicionados }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Localizacao from "@/components/Common/Localizacao"
import LocationPopup from "@/components/Common/LocationPopup"
import SubmenuVW from "@/components/Common/SubmenuVW"
export default {
	components: {
		SubmenuVW,
		LocationPopup,
		Localizacao,
	},
	computed: {
		quantidadeProdutosAdicionados() {
			return this.$store.getters["Carrinho/quantidadeProdutosAdicionados"]
		},
		currentRouteName() {
			return this.$route.name
		},
	},
	methods: {
		botaoMinhaLocalizacaoClick() {
			this.$emit("botaoMinhaLocalizacaoClick")
		},
		newSearch(text) {
			this.$emit("newSearch", text)
		},
		triggerProfile() {
			if (!this.autenticado) {
				this.$emit("botaoRedirecionarLogin")
			} else {
				this.$router.replace({ path: "/minha-conta/meus-dados" })
			}
		},
	},
	props: {
		autenticado: {
			type: Boolean,
		},
		selecionouCidade: {
			type: Boolean,
		},
		cidadeSelecionada: {
			type: String,
		},
		submenuConfig: {
			type: Array,
		},
		ALGOLIA_INDEX_PRODUCTS: {
			type: String,
		},
	},
}
</script>

<style scoped lang="scss">
$theme-color: #001e50;

$fontVwHeadBold: "vwhead-bold", sans-serif;
$fontVwHeadLight: "vwhead-light", sans-serif;
$fontVwTextBold: "vwtext-bold", sans-serif;
$fontVwTextRegular: "vwtext-regular", sans-serif;
.vw__v2 {
	.header__wrapper {
		transition: all 0.3s ease-in-out;
		position: fixed;
		width: 100%;
		color: #fff;
		&.header__sticky {
			z-index: 999;
			position: relative;
			left: 0;
			top: 0;
			width: 100%;
			@media (min-width: 768px) {
				position: fixed;
			}
			.header__vw {
				background: #fff;
				color: #001e50;
				box-shadow: 0 0 25px 2px rgb(0 0 0 / 15%);
				&__logo {
					svg {
						fill: $theme-color;
					}
					&::before {
						background-color: $theme-color;
					}
					&::after {
						background-color: $theme-color;
					}
				}
			}
			.botao-carrinho {
				svg {
					fill: $theme-color;
				}
			}
		}
		.botao-carrinho {
			margin-left: 12px;
			display: flex;
			align-items: center;
			svg {
				height: 34px;
				width: 34px;
				margin-top: -2px;
				fill: #fff;
			}
		}
	}
	.header__vw {
		position: relative;
		color: #fff;
		top: 0;
		left: 0;
		font-size: 14px;
		width: 100%;
		padding: 0;
		@media (min-width: 768px) {
			position: fixed;
		}
		&__bg {
			position: absolute;
			top: 0px;
			height: 116px;
			width: 100%;
			background: linear-gradient(rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0));
			opacity: 1;
		}
		&__menu {
			height: 116px;
			width: 100%;
			max-width: 1220px;
			margin: 0 auto;
			display: grid;
			grid-template-columns: 2fr 1fr;
			z-index: 2;
			.left-side {
				display: grid;
				grid-gap: 10px;
				align-items: center;
				justify-content: flex-start;
			}
			.right-side {
				display: grid;
				grid-gap: 10px;
				align-items: center;
				justify-content: flex-end;
				font-size: 0.9rem;
				text-align: right;
				font-family: $fontVwHeadBold;
			}
		}
		&__logo {
			position: absolute;
			display: grid;
			width: 100%;
			align-items: center;
			grid-template-columns: calc(66.66vw - 14px) max-content auto;
			grid-gap: 14px;
			height: 116px;
			&::before {
				content: "";
				height: 2px;
				background: rgb(255, 255, 255);
			}
			&::after {
				content: "";
				height: 2px;
				background: rgb(255, 255, 255);
			}
		}
	}
}

/** To fix */
.vw__container {
	width: 100%;
}
.flex-column {
	flex-direction: column;
}
.w-100 {
	width: 100%;
}
.component-submenu {
	color: #fff !important;
	border-top: none !important;
}
.component-submenu .container-menu-topo > div .container-botoes-navegacao .botao-menu .container-icone-label > span {
	color: #fff !important;
	font-family: $fontVwHeadBold !important;
	font-size: 1.05rem !important;
}
.align-items-center {
	align-items: center;
}
.right-down {
	display: flex;
	text-align: right;
	i {
		margin-right: 6px;
		font-size: 18px;
	}
	.btn__location {
		.selecionado {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 90px;
		}
	}
}
@media (min-width: 1600px) {
	.btn__location {
		.selecionado {
			max-width: 4vw;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
@media (max-width: 1220px) {
	.moving__frame__top {
		margin-bottom: 0;
		padding: 0 16px;
		width: 100%;
		grid-template-columns: 1fr;
	}
}
.wrapper-submenu {
	height: 100%;
	.container-submenu {
		height: 100%;
		.component-submenu {
			height: 100%;
		}
	}
}
</style>
