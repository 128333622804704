<template>
	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" viewBox="0 0 24 24" class="spinned__loader">
		<path d="M12,5a7,7 0 1,0 0,14a7,7 0 1,0 0,-14" class="stroke__grey"></path>
		<path d="M12,5a7,7 0 1,0 0,14a7,7 0 1,0 0,-14" class="stroke__purple"></path>
	</svg>
</template>

<script>
export default {}
</script>

<style>
@keyframes rotateEffect {
	100% {
		transform: rotate(2turn);
	}
}

@keyframes strokeRotateEffect {
	0% {
		stroke-dashoffset: 43.9823;
	}

	50% {
		stroke-dashoffset: 10.9956;
	}

	100% {
		stroke-dashoffset: 43.9823;
	}
}
.spinned__loader {
	width: 96px;
	height: 96px;
	stroke-width: 2.08333%;
	margin: 32px auto;
}
.stroke__grey {
	stroke: rgb(223, 228, 232);
}
.stroke__purple {
	stroke: var(--loader-color);
	stroke-dasharray: 43.9823, 43.9823;
	transform-origin: center center;
	animation: 2s cubic-bezier(0.41, 0.2, 0.16, 0.59) 0s infinite normal none running rotateEffect, 2s cubic-bezier(0.63, 0, 0.58, 1) 0s infinite normal none running strokeRotateEffect;
}
</style>
