<template>
	<button class="btn" :disabled="disabled" :class="[getSizeClass, getTypeClass]" @click="$emit('click')" @click.prevent.stop="$emit('clickPreventStop')">
		<slot />
		{{ text }}
	</button>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			default: "Comprar",
		},
		size: {
			type: String,
			default: "small",
		},
		type: {
			type: String,
			default: "primary",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		getSizeClass() {
			if (this.size === "small") {
				return "btn--small"
			}
			if (this.size === "large") {
				return "btn--large"
			}
		},
		getTypeClass() {
			if (this.type === "primary") {
				return "btn__primary"
			}
			if (this.type === "secondary") {
				return "btn__secondary"
			}
			if (this.type === "featured") {
				return "btn__featured"
			}
		},
	},
}
</script>

<style scoped lang="scss">
.btn {
	position: relative;
	display: inline-flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	min-height: 44px;
	min-width: 44px;
	padding: 0px 32px;
	border: 2px solid rgb(0, 30, 80);
	margin: 0px;
	border-radius: 500px;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	background: rgb(0, 30, 80);
	color: rgb(255, 255, 255);
	font-family: "VWHead-Regular", sans-serif;
	font-size: 16px;

	&__primary {
		color: #fff;
		&:hover {
			&::before {
				content: "";
				border-radius: 500px;
				position: absolute;
				inset: 0px;
				margin: -2px;
				background-color: rgba(255, 255, 255, 0.2);
			}
		}
	}
	&__secondary {
		background: transparent;
		color: rgb(0, 30, 80);
		&:hover {
			&::before {
				content: "";
				border-radius: 500px;
				position: absolute;
				inset: 0px;
				margin: -2px;
				background-color: rgba(194, 202, 207, 0.2);
			}
		}
	}
	&__featured {
		color: #fff;
		background-color: #00b0f0;
		border: 1px solid #00b0f0;
		&:hover {
			&::before {
				content: "";
				border-radius: 500px;
				position: absolute;
				inset: 0px;
				margin: -2px;
				background-color: rgba(255, 255, 255, 0.2);
			}
		}
	}
}
@media (max-width: 767px) {
	.btn {
		width: 100%;
		height: 52px;
	}
}
</style>
