<template>
	<section id="component-lgpd" v-if="exibir">
		<div :class="['box-flutuante', { exibir: classeExibir }]">
			<div class="mb-2 md:mb-0">
				{{ config.textCookie }}

				<button class="link">
					<a :href="config.filesPrivacyPolicy" target="_blank">
						<span
							>{{ config.linkPrivacyPolicy
							}}<svg class="ml-1" style="height: 12px; width: 12px; display: inline-flex" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
								<path d="M23.489.483c-.621-.611-.728-.645-10.934-.085l.184 3.39c2.268-.123 4.02-.338 5.427-.39L7.635 13.866l2.398 2.408L20.562 5.777c-.061 1.34-.265 3.162-.404 5.404l3.394.21c.623-10.06.591-10.237-.063-10.908Z"></path>
								<path d="M19.6 15.014h3.398V24H0V1h9v3.395H3.399v16.21H19.6Z"></path></svg
						></span>
					</a>
				</button>
			</div>
			<button class="btn__primary max-w-xs" @click="registrarAceiteCookie()">{{ config.botaoConfirm }}</button>
		</div>
	</section>
</template>

<script>
export default {
	name: "ComponentLGPD",
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	components: {},
	data() {
		return {
			exibir: false,
			classeExibir: false,
		}
	},
	mounted() {
		if (localStorage.getItem(this.config.keyCookie) == null) {
			this.exibir = true
		}
	},
	computed: {},
	methods: {
		registrarAceiteCookie() {
			localStorage.setItem(this.config.keyCookie, true)
			this.exibir = false
		},
	},
	watch: {
		exibir(value) {
			if (value) {
				setTimeout(() => {
					this.classeExibir = true
				}, 3000)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
#component-lgpd {
	.box-flutuante {
		max-width: 1140px;
		margin: 0 auto;
		background: rgba(0, 0, 0, 0.7);
		color: #fff;
		padding: 1em;
		position: fixed;
		z-index: 5;
		bottom: -500px;
		left: calc(calc(100% - 1140px) / 2);
		display: flex;
		transition: all 2s;
		font-size: 0.875em;

		@media (max-width: 767px) {
			width: 90%;
			left: 5%;
			display: block;
		}
	}
	.exibir {
		bottom: 2em;
		transition: all 2s;
	}
}
</style>
