import Vue from "vue"
import { Divisor, Badge, Button, Checkbox, Counter, Icon, Link, Numerator, Footer } from "ep-design-system"

Vue.component("Divisor", Divisor)
Vue.component("Badge", Badge)
Vue.component("Button", Button)
Vue.component("Checkbox", Checkbox)
Vue.component("Counter", Counter)
Vue.component("Icon", Icon)
Vue.component("Link", Link)
Vue.component("Numerator", Numerator)
Vue.component("Footer", Footer)
