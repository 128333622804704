<template>
	<div class="notification-tooltip" :class="position" v-if="visibility">
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		visibility: {
			type: Boolean,
			default: true,
		},
		position: {
			type: String,
			default: "normal",
		},
	},
}
</script>

<style scoped lang="scss">
.notification-tooltip {
	padding: 12px;
	color: #fff;
	letter-spacing: 0;
	font-size: 16px;
	line-height: 24px;
	font-weight: normal;
	display: inline-block;
	border-radius: 4px;
	background-color: var(--tooltip-bg-color);
	position: fixed;
	opacity: 1;
	transition: opacity 300ms ease 0s;
	z-index: 9;
	left: 50%;
	overflow-y: auto;
	opacity: 1;
	animation-duration: 0.4s;
	animation-name: fadeInUp;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	&.center {
		top: 50%;
		min-height: 48px;
		max-height: 120px;
	}
	@media (max-width: 767px) {
		width: 90%;
		font-size: 14px;
	}
}
@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate(-50%, -50%) translateY(100%);
	}

	to {
		opacity: 1;
		transform: translate(-50%, -50%) translateY(0px);
	}
}

.fadeInUp {
	animation-name: fadeInUp;
}
</style>
